
.Face2 {
  width: 115px;
  background-color: #16bac5;
  border-radius: 70px;
  -webkit-box-shadow: -10px 0px 0px 0px rgba(0,0,0,0.8);
box-shadow: -10px 0px 0px 0px rgba(0,0,0,0.8);
position: relative;
}

.Contact-text {
  font-size: 42px;
  text-align: center;
  margin-top: 40px;
  width: min(80vw, 600px);
  font-family: Comfort;
}

.Contact-Warning-Text {
  font-size: 25px;
  font-weight: bold;
  text-align: center;
  font-family: Comfort;
  margin-top: 10px;
}

.Contact-label {
  display: flex;
  flex-direction: column;
  font-family: Comfort;
  margin-top: 20px;
  font-size: 17px;
  font-weight: bold;
  color: #999999;
  width: min(80vw, 295px);
}

.Contact-input {
  font-size: 17px;
  font-family: sans;
  border: 1px solid #999999;
  color: #454545;
  padding: 4px;
  border-radius: 4px;
}

.Contact-label2 {
  display: flex;
  flex-direction: column;
  font-family: Comfort;
  margin-top: 20px;
  font-size: 17px;
  font-weight: bold;
  color: #999999;
  width: min(80vw, 600px);
}

.Contact-input2 {
  font-size: 17px;
  font-family: sans;
  height: 200px;
  color: #454545;
  padding: 4px;
  border-radius: 4px;
}

.Contact-Top-Row {
  display: flex;
  flex-direction: row;
  margin-top: 35px;
}

@media screen and (max-width: 700px){
  .Contact-Top-Row {
    flex-direction: column;
  }
  .Contact-label {
    width: min(80vw, 600px);
  }
  .Contact-text {
    font-size: 28px;
  }
}


.Contact-Submit {
  padding-left: 17px;
  padding-right: 17px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #16bac5ff;
  font-size: 22px;
  border: 2px solid #16bac5ff;
  border-radius: 50px;
  cursor: pointer;
  font-family: Sans;
  margin-top: 30px;
  margin-bottom: 30px;
}

.Contact-Submit:hover {
  background-color: #16bac5;
   -webkit-transition: background-color 300ms linear;
   -ms-transition: background-color 300ms linear;
   transition: background-color 300ms linear;
  color:white;
}
