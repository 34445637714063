.M-Logo2 {
  width: 85px;
  height: auto;
  filter: invert(56%) sepia(70%) saturate(484%) hue-rotate(135deg) brightness(92%) contrast(99%);
}
.M-Logo {
  width: 85px;
  height: auto;
}

.M-Logo:hover {
  width:88px
}

.title {
  margin-right: 20px;
  font-size: 20px;
  font-weight: bold;
  font-family: Sans;
  cursor: pointer;
}


.ContactMe {
  padding-left: 17px;
  padding-right: 17px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-right: 15px;
  color: #16bac5ff;
  font-size: 22px;
  border: 2px solid #16bac5ff;
  border-radius: 50px;
  cursor: pointer;
  font-family: Sans;
}

.title:hover {
  text-decoration: underline;
}


.ContactMe:hover {
  background-color: #16bac5;
   -webkit-transition: background-color 300ms linear;
   -ms-transition: background-color 300ms linear;
   transition: background-color 300ms linear;
  color:white;
}


@media screen and (max-width: 800px){
  .M-Logo2 {
    width: 75px;
  }
  .M-Logo {
    width: 75px;
  }
}

@media screen and (max-width: 500px){
  .M-Logo2 {
    width: 65px;
  }
  .M-Logo {
    width: 65px;
  }
}
