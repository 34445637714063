.ebin-square {
  width: 300px;
  height: 300px;
  background: linear-gradient(#3bc4b4, #296f66);
  border-radius: 20px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.web-square {
  width: 300px;
  height: 300px;
  background: linear-gradient(#3bc4b4, #296f66);
  border-radius: 20px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.vml-square {
  width: 300px;
  height: 300px;
  border-radius: 20px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.ebin-square2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  opacity: 0;
  top:0;
  left:0;
  width: 300px;
  height: 300px;
  background-color: #296f66;
  border-radius: 20px;
  -webkit-transition: opacity 0.4s ease-in-out;
  -moz-transition: opacity 0.4s ease-in-out;
  -ms-transition: opacity 0.4s ease-in-out;
  -o-transition: opacity 0.4s ease-in-out;
  transition: opacity 0.4s ease-in-out;
}

.ebin-square2:hover {
  -webkit-transition: opacity 0.4s ease-in-out;
  -moz-transition: opacity 0.4s ease-in-out;
  -ms-transition: opacity 0.4s ease-in-out;
  -o-transition: opacity 0.4s ease-in-out;
  transition: opacity 0.4s ease-in-out;
  opacity: 1;
}

.ebin-pic {
  width: 220px;
}

.web-pic {
  height: auto;
  width: 300px;
}

.web-pic2 {
  height: 300px;
  width: auto;
}

.web-logo {
  height: auto;
  width: 200px;
}

.fridge-square {
  width: 300px;
  height: 300px;
  background: linear-gradient(#2d62b7, #18335f);
  border-radius: 20px;
  display: flex;
  margin: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  font-family: RocknRollOne;
}

.fridge-square2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  opacity: 0;
  top:0;
  left:0;
  width: 300px;
  height: 300px;
  background-color: #18335f;
  border-radius: 20px;
  -webkit-transition: opacity 0.4s ease-in-out;
  -moz-transition: opacity 0.4s ease-in-out;
  -ms-transition: opacity 0.4s ease-in-out;
  -o-transition: opacity 0.4s ease-in-out;
  transition: opacity 0.4s ease-in-out;
}

.fridge-square2:hover {
  -webkit-transition: opacity 0.4s ease-in-out;
  -moz-transition: opacity 0.4s ease-in-out;
  -ms-transition: opacity 0.4s ease-in-out;
  -o-transition: opacity 0.4s ease-in-out;
  transition: opacity 0.4s ease-in-out;
  opacity: 1;
}

.finance-square {
  width: 300px;
  height: 300px;
  background: linear-gradient(#3E9C88, #354B45);
  border-radius: 20px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  font-family: CrimsonText;
}

.finance-square2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  opacity: 0;
  top:0;
  left:0;
  width: 300px;
  height: 300px;
  background-color: #354B45;
  border-radius: 20px;
  -webkit-transition: opacity 0.4s ease-in-out;
  -moz-transition: opacity 0.4s ease-in-out;
  -ms-transition: opacity 0.4s ease-in-out;
  -o-transition: opacity 0.4s ease-in-out;
  transition: opacity 0.4s ease-in-out;
  font-family: CrimsonText;
}

.finance-square2:hover {
  -webkit-transition: opacity 0.4s ease-in-out;
  -moz-transition: opacity 0.4s ease-in-out;
  -ms-transition: opacity 0.4s ease-in-out;
  -o-transition: opacity 0.4s ease-in-out;
  transition: opacity 0.4s ease-in-out;
  opacity: 1;
}


.click-square {
  width: 300px;
  height: 300px;
  background: linear-gradient(#CC444B, #8B4982);
  border-radius: 20px;
  display: flex;
  margin: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  font-weight: bold;
  font-family: Rajdhani;
}

.click-square2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  opacity: 0;
  top:0;
  left:0;
  width: 300px;
  height: 300px;
  background-color: #CC444B;
  border-radius: 20px;
  -webkit-transition: opacity 0.4s ease-in-out;
  -moz-transition: opacity 0.4s ease-in-out;
  -ms-transition: opacity 0.4s ease-in-out;
  -o-transition: opacity 0.4s ease-in-out;
  transition: opacity 0.4s ease-in-out;
  font-family: Rajdhani
}

.vml-square2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  opacity: 0;
  top:0;
  left:0;
  width: 300px;
  height: 300px;
  background-color:#07002D;
  border-radius: 20px;
  -webkit-transition: opacity 0.4s ease-in-out;
  -moz-transition: opacity 0.4s ease-in-out;
  -ms-transition: opacity 0.4s ease-in-out;
  -o-transition: opacity 0.4s ease-in-out;
  transition: opacity 0.4s ease-in-out;
  font-family: Rajdhani
}

.vml-square2:hover {
  -webkit-transition: opacity 0.4s ease-in-out;
  -moz-transition: opacity 0.4s ease-in-out;
  -ms-transition: opacity 0.4s ease-in-out;
  -o-transition: opacity 0.4s ease-in-out;
  transition: opacity 0.4s ease-in-out;
  opacity: 1;
}

.click-square2:hover {
  -webkit-transition: opacity 0.4s ease-in-out;
  -moz-transition: opacity 0.4s ease-in-out;
  -ms-transition: opacity 0.4s ease-in-out;
  -o-transition: opacity 0.4s ease-in-out;
  transition: opacity 0.4s ease-in-out;
  opacity: 1;
}

.recent-text {
  width: 99vw;
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  font-family: Sans;
  margin-top: 120px;
}

.recent-text2 {
  width: 99vw;
  max-width: 90vw;
  font-size: 20px;
  text-align: center;
  font-family: Sans;
  margin-top: 10px;
}



.recent-app-description {
  width: 250px;
  text-align: center;
  font-size: 23px;
  color: white;
  margin-top: 15px;
}

.recent-app-title {
  width: 300px;
  text-align: center;
  font-size: 30px;
  color: white;
  margin-top: 15px;
}
.recent-app-title-bot {
  width: 300px;
  text-align: center;
  font-size: 30px;
  color: white;
  margin-bottom: 15px;
}


.recent-stores {
  width:250px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 40px;
}

.recent-store {
  width: 50px;
  cursor: pointer;
  border-radius: 8px;
}
.recent-store:hover {
  -webkit-box-shadow: 3px 3px 3px 3px rgba(0,0,0,0.73);
box-shadow: 3px 3px 3px 3px rgba(0,0,0,0.73);
}

.recent-main-row {
  width: min(99vw, 1100px);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  margin-top: 75px;
  margin-bottom: 100px;
}

.recent-two-row {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.web-title-vml {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Sans;
  font-size: 40px;
  font-weight: 900;
  border-radius: 20px;
  color: #07002D;
}



.web-title {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  background-color: rgba(59,0,12,0.5);
  border-radius: 20px;
  color: white;
}

.web-title2 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  background-color: rgba(79,51,0,0.5);
  border-radius: 20px;
  color: white;
}

.syphus-square2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  opacity: 0;
  top:0;
  left:0;
  width: 300px;
  height: 300px;
  background-color: #dd7525;
  border-radius: 20px;
  -webkit-transition: opacity 0.4s ease-in-out;
  -moz-transition: opacity 0.4s ease-in-out;
  -ms-transition: opacity 0.4s ease-in-out;
  -o-transition: opacity 0.4s ease-in-out;
  transition: opacity 0.4s ease-in-out;
  font-family: Staatliches;
}

.syphus-square2:hover {
  -webkit-transition: opacity 0.4s ease-in-out;
  -moz-transition: opacity 0.4s ease-in-out;
  -ms-transition: opacity 0.4s ease-in-out;
  -o-transition: opacity 0.4s ease-in-out;
  transition: opacity 0.4s ease-in-out;
  opacity: 1;
}

@media screen and (max-width: 800px){
  .recent-two-row {
    flex-direction: column;
  }
}
