.One {
  text-align: center;
  backdrop-filter: blur(8px);
}

.Face {
  margin-top: 30px;
  width: 250px;
  background-color: #16bac5;
  border-radius: 120px;
  -webkit-box-shadow: -23px 0px 0px 0px rgba(0,0,0,0.8);
box-shadow: -23px 0px 0px 0px rgba(0,0,0,0.8);
}

.hardware {
  height: 200px;
}

.Main-text {
  font-size: 45px;
  font-family: Sans;
  font-weight: bold;
  color: #171d1c;
  max-width: min(800px, 90vw);
  text-align: center;
  margin-top: 15px;
}

.greet {
  font-size: 35px;
  font-family: Sans;
  font-weight: bold;
  color: white;
  margin-top: 60px;
  max-width: min(800px, 80vw);
}

.greet2 {
  font-size: 22px;
  font-family: Sans;
  color: white;
  margin-top: 60px;
  width: 90vw;
  max-width: 900px;
  text-align: center;
}


@media screen and (max-width: 800px){
    .Face {
      width: 180px;
      border-radius: 90px;
      -webkit-box-shadow: -18px 0px 0px 0px rgba(0,0,0,0.8);
    box-shadow: -18px 0px 0px 0px rgba(0,0,0,0.8);
    }
    .Main-text {
      font-size: 38px;
    }
    .greet {
      font-size: 30px
    }
}

@media screen and (max-width: 500px){
    .Face {
      width: 120px;
      border-radius: 60px;
      -webkit-box-shadow: -13px 0px 0px 0px rgba(0,0,0,0.8);
    box-shadow: -13px 0px 0px 0px rgba(0,0,0,0.8);
    }
    .Main-text {
      font-size: 32px;
    }
    .greet {
      font-size: 25px
    }
}
