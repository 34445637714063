

@font-face {
  font-family: 'Nasa';
  src: local('Nasa'), url(./assets/fonts/nasalization-rg.ttf) format("truetype");
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(./assets/fonts/Roboto-Regular.ttf) format("truetype");
}

@font-face {
  font-family: 'Sans';
  src: local('Sans'), url(./assets/fonts/OpenSans-Regular.ttf) format("truetype");
}

@font-face {
  font-family: 'Comfort';
  src: local('Comfort'), url(./assets/fonts/Comfortaa-Regular.ttf) format("truetype");
}

@font-face {
  font-family: 'Staatliches';
  src: local('Staatliches'), url(./assets/fonts/Staatliches-Regular.ttf) format("truetype");
}

@font-face {
  font-family: 'CrimsonText';
  src: local('CrimsonText'), url(./assets/fonts/CrimsonText-Regular.ttf) format("truetype");
}

@font-face {
  font-family: 'Rajdhani';
  src: local('Rajdhani'), url(./assets/fonts/Rajdhani-Regular.ttf) format("truetype");
}

@font-face {
  font-family: 'RocknRollOne';
  src: local('RocknRollOne'), url(./assets/fonts/RocknRollOne-Regular.ttf) format("truetype");
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
