
@font-face {
    font-family: 'QuickSand';
    src: local('QuickSand'), url(../../assets/fonts/Quicksand-SemiBold.ttf) format("truetype");
  }


.TicTac {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 140px;
  margin-top: 45px;
}

.TicTac-Title {
  margin-right: 10vw;
  margin-left: 10vw;
  text-align: center;
}
    
.row{
    flex-direction: row;
    width:min(50vw, 400px);
    flex-wrap: wrap;
    padding-top:min(2vw, 18px);
    padding-bottom:min(2vw, 18px);
    display:flex;
    align-items:center;
    justify-content:center;
    background-color: rgb(226, 226, 226);
    border-radius: min(2vw, 18px);
}

.reset{
    float:left;
    background-color:teal;
    margin-left:15px;
    margin-top:15px;
    border-radius:5px;
    padding:3px;
    cursor:pointer;
    color:#fff
}

.Board {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Board-Horizontal {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Board-Vertical {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(90deg);
}

.Board-Line {
    height: min(40vw, 300px);
    margin-right: min(3.5vw, 30px);
    margin-left: min(3.5vw, 30px);
}

.Result-Background {
  position:absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom:0;
  background-color: rgb(179, 179, 179, 0.5);
  z-index: 5;
  border-radius: min(1vw, 8px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  pointer-events: all;
}

.Result-Button {
  padding: 2px 20px;
  font-size: 25px;
  cursor: pointer;
  border: 3px solid #16bac5;
  background-color: #16bac5;
  color: white;
  border-radius: 20px;
}

.Result-Button:hover {
  background-color: #70cbd1;
}

.box {
  position: relative;
  width:min(40vw, 300px);
  height:min(40vw, 300px);
  border-radius: 10px;
}
.box-contents {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 50px rgba(0, 0, 0, .3);
  transition: .5s ease;
  z-index: 1;
}
.hover-point-visble {
    position: absolute;
    z-index: 1;
    width: calc(100% / 3);
    height: calc(100% / 3);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: min(6vw, 60px);
    font-family: QuickSand;
    color: #16bac5;
}
.hover-point {
  position: absolute;
  z-index: 2;
  width: calc(100% / 3);
  height: calc(100% / 3);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: min(6vw, 60px);
  font-family: QuickSand;
  color: #16bac5;
}
.hover-point:nth-child(1) {
  top: 0;
  left: 0;
}
.hover-point:nth-child(2) {
  top: 0;
  left: calc(100% / 3);
}
.hover-point:nth-child(3) {
  top: 0;
  right: 0;
}
.hover-point:nth-child(4) {
  top: calc(100% / 3);
  left: 0;
}
.hover-point:nth-child(5) {
  top: calc(100% / 3);
  left: calc(100% / 3);
}
.hover-point:nth-child(6) {
  top: calc(100% / 3);
  right: 0;
}
.hover-point:nth-child(7) {
  bottom: 0;
  left: 0;
}
.hover-point:nth-child(8) {
  bottom: 0;
  left: calc(100% / 3);
}
.hover-point:nth-child(9) {
    bottom: 0;
    right: 0;
}


.hover-point-visble:nth-child(1) {
    top: 0;
    left: 0;
  }
  .hover-point-visble:nth-child(2) {
    top: 0;
    left: calc(100% / 3);
  }
  .hover-point-visble:nth-child(3) {
    top: 0;
    right: 0;
  }
  .hover-point-visble:nth-child(4) {
    top: calc(100% / 3);
    left: 0;
  }
  .hover-point-visble:nth-child(5) {
    top: calc(100% / 3);
    left: calc(100% / 3);
  }
  .hover-point-visble:nth-child(6) {
    top: calc(100% / 3);
    right: 0;
  }
  .hover-point-visble:nth-child(7) {
    bottom: 0;
    left: 0;
  }
  .hover-point-visble:nth-child(8) {
    bottom: 0;
    left: calc(100% / 3);
  }
  .hover-point-visble:nth-child(9) {
      bottom: 0;
      right: 0;
  }



.hover-point:nth-child(1):hover ~ .box-contents {
  box-shadow: 15px 15px 50px rgba(0, 0, 0, .3);
  transform-origin: right top;
  transform: perspective(1000px) rotateX(5deg) rotateY(-5deg) rotateZ(1deg);
}
.hover-point:nth-child(2):hover ~ .box-contents {
  box-shadow: 0 15px 50px rgba(0, 0, 0, .3);
  transform-origin: center top;
  transform: perspective(1000px) rotateX(10deg);
}
.hover-point:nth-child(3):hover ~ .box-contents {
  box-shadow: -15px 15px 50px rgba(0, 0, 0, .3);
  transform-origin: left top;
  transform: perspective(1000px) rotateX(5deg) rotateY(5deg) rotateZ(-1deg);
}
.hover-point:nth-child(4):hover ~ .box-contents {
  box-shadow: 15px 5px 50px rgba(0, 0, 0, .3);
  transform-origin: left center;
  transform: perspective(1000px) rotateY(-5deg);
}
.hover-point:nth-child(5):hover ~ .box-contents {
  box-shadow: -15px 5px 50px rgba(0, 0, 0, .3);
  transform-origin: right center;
  transform: perspective(1000px) rotateY(5deg);
}
.hover-point:nth-child(6):hover ~ .box-contents {
  box-shadow: 15px -15px 50px rgba(0, 0, 0, .3);
  transform-origin: right bottom;
  transform: perspective(1000px) rotateX(-5deg) rotateY(-5deg) rotateZ(-1deg);
}
.hover-point:nth-child(7):hover ~ .box-contents {
  box-shadow: 0 -15px 50px rgba(0, 0, 0, .3);
  transform-origin: center bottom;
  transform: perspective(1000px) rotateX(-5deg);
}
.hover-point:nth-child(8):hover ~ .box-contents {
  box-shadow: -15px -15px 50px rgba(0, 0, 0, .3);
  transform-origin: left bottom;
  transform: perspective(1000px) rotateX(-5deg) rotateY(5deg) rotateZ(1deg);
}