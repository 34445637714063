.Row-Build {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  position: relative;
  top: 20px;
  -webkit-box-shadow: 0px 0px 8px 5px rgba(0,0,0,0.41);
box-shadow: 0px 0px 8px 5px rgba(0,0,0,0.41);
border-radius: 20px;
}

.Item-Build1 {
  width: 250px;
  height: 400px;
  background-color: white;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.Item-Build2 {
  width: 250px;
  height: 400px;
  background-color: white;
  border-left: 1px solid #858585;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.Item-Build3 {
  width: 250px;
  height: 400px;
  background-color: white;
  border-left: 1px solid #858585;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.image-Build {
  width: 70px;
  margin-top: 10px;
}

.Item-Text {
  font-size: 22px;
  font-weight: bold;
  margin-top: 10px;
  font-family: Sans;
}
.Item-Text-sub {
  font-size: 20px;
  margin-top: 30px;
  margin-left: 5px;
  margin-right: 5px;
  font-family: Sans;
}



@media screen and (max-width: 1000px){
    .Row-Build {
      width:80vw;
      border-radius: 20px;
      flex-direction: column;
    }
    .Item-Build1 {
      width: 80vw;
      border-top-right-radius: 20px;
      height: 330px;
    }
    .Item-Build2 {
      width: 80vw;
      border-left: 0px solid #858585;
      border-top: 1px solid #858585;
      height: 330px;
    }
    .Item-Build3 {
      width: 80vw;
      border-left: 0px solid #858585;
      border-top: 1px solid #858585;
      border-bottom-right-radius: 20px;
      border-bottom-left-radius: 20px;
      height: 330px;
    }
}


.list-styled {
  padding-left: 1em;
  text-indent: -.7em;
  font-size: 20px;
  font-family: Sans;
  width: 190px;
}

.list-item {
  list-style: none;
  padding: 0;
  margin: 0;
}



.list-styled::before {
  content: "• ";
  color: #16bac5; /* or whatever color you prefer */
}
