.Chat-Footer-Grid {
  width: 90vw;
  max-width: 1100px;
  background-color: #171d1c;
  -webkit-box-shadow: 2px 2px 2px 2px rgba(0,0,0,0.71);
  box-shadow: 2px 2px 2px 2px rgba(0,0,0,0.71);
  border-radius: 17px;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 40px;
  padding-bottom: 40px;
}


.Chat-Footer-Title {
  color: white;
  font-size: 25px;
  font-weight: bold;
  width: min(30vw, 367px);
  text-align: center;
  font-family: sans
}

.Chat-Footer-Description {
  color: white;
  font-size: 18px;
  width: min(30vw, 367px);
  text-align: center;
  font-family: sans
}

.Chat-Footer-Button {
  width: min(30vw, 367px);
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-family: sans
}

.Chat-Footer-Button-Center {
  color: white;
  font-size: 20px;
  padding: 6px;
  padding-right: 20px;
  padding-left: 20px;
  border: 2px solid #16bac5ff;
  border-radius: 25px;
  cursor: pointer;
}

.Chat-Footer-Button-Center:hover {
  background-color: #16bac5ff;
   -webkit-transition: background-color 200ms linear;
   -ms-transition: background-color 200ms linear;
   transition: background-color 200ms linear;
}




.M-Logo2-Footer {
  width: 55px;
  height: auto;
  filter: invert(56%) sepia(70%) saturate(484%) hue-rotate(135deg) brightness(92%) contrast(99%);
}
.M-Logo-Footer {
  width: 55px;
  height: auto;
}

.Logo-Footer-View {
  margin-top: 70px;
  cursor: pointer;
  position: relative;
  background-color: white;
  padding: 10px;
  margin-bottom: 37px;
  border-radius: 15px;

  -webkit-box-shadow: 0px 0px 10px 3px rgba(0,0,0,0.23);
box-shadow: 0px 0px 10px 3px rgba(0,0,0,0.23);
}


.Logo-Footer-View:hover {
  -webkit-box-shadow: 0px 0px 10px 6px rgba(0,0,0,0.23);
box-shadow: 0px 0px 10px 6px rgba(0,0,0,0.23);
}








.Chat-Footer-Grid2 {
  width: 90vw;
  max-width: 1100px;
  background-color: #171d1c;
  -webkit-box-shadow: 2px 2px 2px 2px rgba(0,0,0,0.71);
  box-shadow: 2px 2px 2px 2px rgba(0,0,0,0.71);
  border-radius: 17px;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
}


.Chat-Footer-Title2 {
  color: white;
  font-size: 25px;
  font-weight: bold;
  width: min(80vw, 600px);
  text-align: center;
  font-family: sans
}

.Chat-Footer-Description2 {
  color: white;
  font-size: 18px;
  width: min(80vw, 600px);
  text-align: center;
  margin-top: 25px;
  margin-bottom: 25px;
  font-family: sans
}

.Chat-Footer-Button2 {
  width: min(80vw, 600px);
  display: flex;
  flex-direction: row;
  justify-content: center;
}


.Footer-Quote {
  color: #e8e8e8;
  font-size: 20px;
  font-family: sans;
  width: min(70vw, 300px);
  margin-bottom: 45px;
  text-align: center;
}

.Footer-Contact {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 35px;
  width: min(80vw, 400px)
}


@media screen and (max-width: 800px){
  .Footer-Contact {
    flex-direction: column;
  }
}
