.six {
  text-align: center;
  backdrop-filter: blur(8px);
}

.About-Title {
  margin-top: 10px;
  font-size: 30px;
  font-weight: bold;
  width: min(75vw, 900px);
  font-family: sans;
}
.About-Description {
  margin-top: 20px;
  font-size: 25px;
  width: min(75vw, 900px);
  font-family: sans;
}

.About-Pic {
  margin-top: 50px;
  width:140px;
  height: 180px;
  border-radius: 20px;
}

.About-Pic-View {
  width: min(75vw, 900px);
}

.About-Link {
  color: #16bac5;
  cursor: pointer;
  text-decoration: underline;
}
